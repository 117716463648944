import React, { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

export const WebSocketContext = createContext();

const WebSocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [token,setToken] = useState(localStorage.getItem("TOKEN"))
  useEffect(() => {
    const newSocket = io('https://back-live.bmstore.org',{
      // handshake: {
      //   headers: {
      //     authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRrYXJlbGkwN0BnbWFpbC5jb20iLCJzdWIiOjEsImlhdCI6MTcwODA3MzYzN30.KjSOpcEUqY-8JqkS-qjSx-6LjEgUmX0Nj_dGgwa18j4`,
      //   }
      // },
      extraHeaders: {
        Authorization: `Bearer ${token}`, // Add authorization header with Bearer token
      },
    });
    setSocket(newSocket);
    console.log(newSocket)
    return () => newSocket.close();
  }, []);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContextProvider;
