import axios from "axios";

const axiosClient = axios.create({
  baseURL: `https://back-live.bmstore.org/`,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("TOKEN")
  config.headers.authorization = `Bearer ${token}`;
  return config;
});

// axiosClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const { response } = error;
//     if (response.status === 401) {
//       localStorage.removeItem("BAITI_TOKEN");
//       // window.location.reload();
//     } else if (response.status === 404) {
//       //Show not found
//     }

//     throw error;
//   }
// );

export default axiosClient;