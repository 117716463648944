// src/components/CarComponent.js
import React, { useContext, useState, useEffect } from 'react';
import { WebSocketContext } from './WebSocketContext';
import { PrimaryContext } from './MainContext';
import axiosClient from './axiosClient';

const SecondChat = ({userId}) => {
  const socket = useContext(WebSocketContext);
  const { user } = useContext(PrimaryContext);
  const [message, setMessage] = useState([]);
  const [messageValue, setMessageValue] = useState('');

  useEffect(() => {
    setMessage([])
    axiosClient.get(`messenger/message/${userId}`)
      .then((res) => setMessage(res.data.data))
      .catch((err) => console.log(err));
  }, [userId]);
  const sentMessage = () =>{
    // console.log('messageValue')
    if(messageValue !==''){
      axiosClient.post('messenger/message',{
        message: messageValue,
        toUserId: userId
      })
        .then(() => setMessageValue(''))
    }
  }
  useEffect(() => {
    if (socket) {
      socket.on(`sentMessage/${user.id}/${userId}`, (message) => {
        setMessage((prev) => [
          ...prev,
          {...message}
        ]);
        // Update car view in the frontend
      });
    }
    return () => {
      if (socket) {
        socket.off(`sentMessage/${user.id}/${userId}`);
      }
    };
  }, [socket,userId]);


  

  return (
    <div className='w-full h-full justify-center'>
      <div className='w-full p-3'>
        {
          message.map((item) => (
            <div className={`flex ${user.id === item.fromUser.id ? "justify-end": "justify-start"}`}>
              <div className={`flex  h-auto gap-2 ${user.id === item.fromUser.id ? "bg-blue-600": "bg-green-600"}`}>
                <h1>
                  {item.fromUser.fname}:
                </h1>
                <h1>{item.message}</h1>
              </div>
            </div>
          ))
        }
      </div>
      <div className='w-full items-end'>
        <input className='w-32 ' placeholder='sent message' value={messageValue} onChange={(e) => setMessageValue(e.target.value)}/>
        <button onClick={sentMessage}>sent</button>
      </div>
    </div>
  );
};

export default SecondChat;
