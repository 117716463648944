import logo from './logo.svg';
import './components/style/output.css';
import Cars from './components/Cars';
import WebSocketContextProvider from './components/WebSocketContext';
import Message from './components/Message';
import Chat from './components/Chat';
import SecondChat from './components/SecondChat';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Users from './components/Users';
import FriendRequests from './components/FriendRequests';

function App() {
  return (
    <WebSocketContextProvider>
      <Header/>
      <Routes>
        <Route path="/" element={<Chat/>} />
        <Route path="/chat" element={<Chat/>} />
        <Route path="/user" element={<Users/>} />
        <Route path="/friendRequest" element={<FriendRequests/>} />
      </Routes>
    </WebSocketContextProvider>
  );
}

export default App;
