import React, { useContext, useEffect, useState } from 'react'
import axiosClient from './axiosClient'
import { PrimaryContext } from './MainContext';
import UserOne from './UserOne';

const Users = () => {
  // const { userFriend, setUserFriend, userFriendRequest, setUserFriendRequest} = useContext(PrimaryContext);
  const [allUser,setAllUser] = useState([])
  useEffect(() => {
    axiosClient.get('messenger/user')
    .then((res) => setAllUser(res.data.data))
    .catch((err) => console.log(err))
  },[])

  // const addFriend = (userId) => {
  //   axiosClient.post('user/friendRequest',{toUserId:userId})
  //   .then((res) => setUserFriendRequest((prev) => [...prev, {...res.data}]) )
  //   .catch((err) => console.log(err))
  // }
  // const acceptRequest = (reqId) => {
  //   axiosClient.post(`user/acceptFriendRequest/${reqId}`)
  //   .then((res) => setUserFriend((prev) => [...prev, {...res.data}]))
  //   .catch((err) => console.log(err))
  // }
  return (
    <div className="flex flex-col gap-5">
      {
        allUser.map((item) => (
          // <div className="flex gap-10">
          //   <h1>{item.fname}</h1>
          //   {
          //     (userFriendRequest.filter((item1) => item1.to === item.id).length > 0 || userFriendRequest.filter((item1) => item1.from === item.id).length > 0 ) ?
          //     (
          //       userFriendRequest.filter((item1) => item1.to === item.id).length > 0 ? 
          //         (<div className='flex gap-5 cursor-pointer'>
          //           <h1>gagzavnilia</h1>
          //           <h>gauqmeba</h>
          //         </div>) :
          //         (<div className='flex gap-5 cursor-pointer'>
          //           <h1>gamogzavnilia</h1>
          //           <h onClick={() => acceptRequest(item)}>datanxmeba</h>
          //           <h1>gauqmeba</h1>
          //         </div>)
          //     ):
          //     (userFriend.filter((item1) => (item1.to === item.id || item1.from === item.from))[0] ? (
          //       <h1>delete</h1>
          //       ) : (
          //         <h1 onClick={() => addFriend(item.id)}>add friend</h1>
          //       ))
          //   }
          // </div>
          <UserOne key={item.id} userOne={item}/>
        ))
      }
    </div>
  )
}

export default Users