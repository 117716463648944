import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <div className="w-full h-auto flex justify-center items-center gap-10">
      <Link to='/user'>
        <h1>all user</h1>
      </Link>
      <Link to='/friend'>
        <h1>all friend</h1>
      </Link>
      <Link to='/chat'>
        <h1>chat</h1>
      </Link>
    </div>
  )
}

export default Header