import { useState, createContext, useEffect } from "react";
import axiosClient from "./axiosClient";
export const PrimaryContext = createContext(null);
function MainContext({ children }) {

  const [signed, setSigned] = useState(true);

  const [user, setUser] = useState({});
  const [userFriend, setUserFriend] = useState([]);
  const [userFriendReRender, setUserFriendReRender] = useState(1);
  const [userFriendRequest, setUserFriendRequest] = useState([]);
  const [userFriendRequestReRender, setUserFriendRequestReRender] = useState(1);
  //token
  const [token, _setToken] = useState(localStorage.getItem("BAITI_TOKEN"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);


  //setting token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("BAITI_TOKEN", token);
    } else {
      localStorage.removeItem("BAITI_TOKEN");
    }
  };

  //get current logged in user
  useEffect(() => {
    axiosClient
      .get("messenger/currentUser")
      .then(({ data }) => {
        setUser(data);
      })
      .catch((err) => console.log(err));
  }, [token]);

  useEffect(() => {
    axiosClient
      .get("user/friend")
      .then((res) => {
        setUserFriend(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [token,userFriendReRender]);

  useEffect(() => {
    axiosClient
      .get("user/friendRequest")
      .then((res) => {
        setUserFriendRequest(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [token,userFriendRequestReRender]);


  //check if user is authoruized and save result in state
  useEffect(() => {
    if (user.phone || user.email) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  //------------------------------- favorites
  return (
    <PrimaryContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthorized,
        setIsAuthorized,
        userFriend,
        setUserFriend,
        userFriendRequest,
        setUserFriendRequest,
        setUserFriendReRender,
        setUserFriendRequestReRender
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
}

export default MainContext;
