import React, { useContext, useEffect, useState } from 'react'
import { PrimaryContext } from './MainContext';
import axiosClient from './axiosClient';

const UserOne = ({userOne}) => {
  const { 
    userFriend, 
    setUserFriend, 
    userFriendRequest, 
    setUserFriendRequest,
    // setUserFriendReRender,
    // setUserFriendRequestReRender
  } = useContext(PrimaryContext);
  const [isInFriendRequestTo,setIsInFriendRequestTo] = useState()
  const [isInFriendRequestFrom,setIsInFriendRequestFrom] = useState()
  const [isFriend,setIsFriend] = useState()
  useEffect(() => {
    const isFriend = userFriend.filter((item1) => (item1.to === userOne.id || item1.from === userOne.id))
    const isFriendTo = userFriendRequest.filter((item1) => item1.to === userOne.id)
    const isFriendFrom = userFriendRequest.filter((item1) => item1.from === userOne.id)
    if(isFriend.length>0){
      setIsFriend(isFriend[0])
    }
    if(isFriendTo.length>0){
      setIsInFriendRequestTo(isFriendTo[0])
    }
    if(isFriendFrom.length>0){
      setIsInFriendRequestFrom(isFriendFrom[0])
    }
  },[userFriend,userFriendRequest,userOne])
  const addFriend = (userId) => {
    axiosClient.post('user/friendRequest',{toUserId:userId})
    .then((res) => setUserFriendRequest((prev) => [...prev, {...res.data}]) )
    .catch((err) => console.log(err))
  }
  ///
  const acceptRequest = () => {
    axiosClient.post(`user/acceptFriendRequest/${isInFriendRequestFrom.id}`)
    .then((res) => {setUserFriend((prev) => [...prev, {...res.data}]);setIsInFriendRequestFrom()})
    .catch((err) => console.log(err))
  }
  const rejectRequest = () => {
    axiosClient.delete(`user/friendRequest/${isInFriendRequestFrom.id}`)
    .then((res) => deleteFrontFrom())
    .catch((err) => console.log(err))
  }
  const deleteFrontFrom = () => {
    const newData = userFriendRequest.filter((item) => item.id !== isInFriendRequestFrom.id)
    setIsInFriendRequestFrom()
    setUserFriendRequest(newData)
  } 
  /////
  const deleteFrontTo = () => {
    const newData = userFriendRequest.filter((item) => item.id !== isInFriendRequestTo.id)
    setIsInFriendRequestTo()
    setUserFriendRequest(newData)
  } 
  const deleteRequest = () => {
    axiosClient.delete(`user/friendRequest/${isInFriendRequestTo.id}`)
    .then((res) => deleteFrontTo())
    .catch((err) => console.log(err))
  }
  const deleteFriendFront = () => {
    const newData = userFriend.filter((item) => item.id !== isFriend.id)
    setIsFriend()
    setUserFriend(newData)
  }
  const deleteFriend = () => {
    axiosClient.delete(`user/friend/${isFriend.id}`)
    .then((res) => deleteFriendFront())
    .catch((err) => console.log(err))
  }
  return (
    <div className="flex gap-10">
      <h1>{userOne.fname}</h1>
      {
        (isInFriendRequestTo || isInFriendRequestFrom ) ?
        (
          isInFriendRequestTo ? 
            (<div className='flex gap-5 cursor-pointer'>
              <h1>gagzavnilia</h1>
              <h1 onClick={() => deleteRequest()}>gauqmeba</h1>
            </div>) :
            (<div className='flex gap-5 cursor-pointer'>
              <h1>gamogzavnilia</h1>
              <h1 onClick={() => acceptRequest()}>datanxmeba</h1>
              <h1 onClick={() => rejectRequest()}>gauqmeba</h1>
            </div>)
        ):
        (isFriend ? (
          <h1 onClick={() => deleteFriend()}>delete</h1>
          ) : (
            <h1 onClick={() => addFriend(userOne.id)}>add friend</h1>
          ))
      }
    </div>
  )
}

export default UserOne