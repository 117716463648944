import React, { useContext, useEffect, useState } from 'react'
import axiosClient from './axiosClient'
import { PrimaryContext } from './MainContext';
import SecondChat from './SecondChat';

const Chat = () => {
  const { user, userFriend } = useContext(PrimaryContext);
  console.log(user)
  const [users,setUsers] = useState([])
  const [clickedUserId,SetClickUserId] = useState(null)
  useEffect(() => {
    axiosClient.get('messenger/user')
    .then((res) => setUsers(res.data.data))
    .catch((err) => console.error(err))
  },[])
  console.log(userFriend,'sdasdasdasdsa')
  return (
    <div className='flex w-full h-screen'>
      <div className='flex flex-col gap-5 w-[20%]'>
        {
          userFriend.map((item) => (
            <div onClick={() => SetClickUserId( item.to === user.id ? item.from : item.to )}>
              {
                item.toUser.id !== user.id ? 
                <h1>{item.toUser.fname}</h1>:
                <h1>{item.fromUser.fname}</h1>
              }
            </div>
          ))
        }
      </div>
      {
        clickedUserId && 
        <div className='flex w-[80%]'>
          <SecondChat userId={clickedUserId}/>
        </div>
      }
    </div>
  )
}

export default Chat